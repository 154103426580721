@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 5px;
  margin-bottom: 5px;
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0px; /* Adjust width for mobile */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* You can change this to match mobile theme */
  }

  ::-webkit-scrollbar-thumb {
    background: #aaa; /* You can change this to match mobile theme */
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.no-scroll {
  overflow: hidden !important;
}

body.no-scroll,
html.no-scroll {
  height: 100svh;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 142 86% 28%;
    --primary-foreground: 356 29% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 45%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142 86% 28%;
    --chart-1: 142 86% 28%;
    --chart-1: 139 65% 20%;
    --chart-2: 140 74% 44%;
    --chart-3: 142 88% 28%;
    --chart-4: 137 55% 15%;
    --chart-5: 141 40% 9%;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 142 86% 28%;
    --primary-foreground: 356 29% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142 86% 28%;
    --chart-1: 142 88% 28%;
    --chart-2: 139 65% 20%;
    --chart-3: 140 74% 24%;
    --chart-4: 137 55% 15%;
    --chart-5: 141 40% 9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
